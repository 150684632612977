import React from 'react'
import { Link } from 'gatsby'
import logo from '../img/shinsegae_logo_eng.jpg';
import TranslationIcon1 from '../img/korea.svg';
import TranslationIcon2 from '../img/usa_flag.png';
import logoTemp from '../img/inc_eng_1.png';
import LangContext from '../context/LangContext'

const Navbar = class extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      active: false,
      navBarActiveClass: '',
    }
  }
  static contextType = LangContext;

  toggleHamburger = () => {
    // toggle the active boolean in the state
    this.setState({
      active: !this.state.active,
    },
      () => {
        this.state.active ? this.setState({
          navBarActiveClass: 'is-active',
        })
          : this.setState({
            navBarActiveClass: '',
          })
      }
    )
  }


  render() {
    const { lang, toggleLang } = this.context;
    const location = this.props.location;

    return (
      <nav className="navbar is-transparent" role="navigation" aria-label="main-navigation">
        <div className="container">
          <div className="navbar-brand">
            <Link to="/" className="navbar-item" title="Logo">
              <img src={logoTemp} alt="Shinsegae I&C" style={{ width: '300px', maxHeight: '5.5rem' }} />
            </Link>
            {/* Hamburger menu */}
            <div
              className={`navbar-burger burger ${this.state.navBarActiveClass}`}
              data-target="navMenu"
              onClick={() => this.toggleHamburger()}
            >
              <span />
              <span />
              <span />
            </div>
          </div>
          <div id="navMenu" className={`navbar-menu ${this.state.navBarActiveClass}`}>
            <div className="navbar-start">
              <Link className="navbar-item" to="/" lang={lang}>Services</Link>
              <Link className="navbar-item" to="/case" lang={lang}>Case Study</Link>
              <Link className="navbar-item" to="/news" lang={lang}>Tech Blog</Link>
              <Link className="navbar-item" to="/contact" lang={lang}>Contact</Link>
            </div>
            {location !== undefined ?
            <span />
            : 
            <button className="lang-button" onClick={() => toggleLang()}>{lang === 'kr' ? 
            <img src={TranslationIcon2} alt="USA" /> :
            <img src={TranslationIcon1} alt="KOREA" /> }</button>
            }
          </div>
        </div>
      </nav>
    )
  }
}

export default Navbar
