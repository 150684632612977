import React from 'react'

const Footer = class extends React.Component {
  render() {
    return (
      <footer className="footer">
        <div>
          <hr className="footer-line" color="gray" />
          <p className="footer-copyright">© Copyright <b>Shinsegae I&C</b> All Rights Reserved.</p>
        </div>
      </footer>
    )
  }
}

export default Footer
